

































import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '@/lib/util'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import CreateContact from './create-contact.vue'
import RegisterUserForContact from "@/store/entities/companycontext/registeruserforcontact";
import ContactDto from "@/store/entities/companycontext/contact";
import SortingModel from "@/store/entities/sortingmodel";

class PageContactRequest extends PageRequest {
}

@Component({
    components: {CreateContact}
})
export default class Contact extends AbpBase {

    async edit(id: number) {
        this.$store.commit('contact/edit', await this.$store.dispatch({
            type: 'contact/get',
            data: id
        }));
        await this.$router.push({name: 'edit-contact', params: {contactId: id.toString()}});
        await this.getPage();
    }


    pageRequest: PageContactRequest = new PageContactRequest();

    createModalShow: boolean = false;
    editModalShow: boolean = false;

    get list() {
        return this.$store.state.contact.list;
    };

    get loading() {
        return this.$store.state.contact.loading;
    }

    async changeSort(data) {
        if (this.pageRequest.sorting == null) {
            this.pageRequest.sorting = [];
        }

        let item = this.pageRequest.sorting.find(x => x.fieldName === data.key)
        if (item == null) {
            let sortingModel = new SortingModel();
            sortingModel.fieldName = data.key;
            sortingModel.isDescendingDirection = data.order === "desc";
            this.pageRequest.sorting.push(sortingModel)
        } else {
            item.isDescendingDirection = data.order === "desc";
        }
        await this.getPage();
    }

    create() {
        this.$store.commit('contact/edit', new ContactDto());
        this.createModalShow = true;
    }

    pageChange(page: number) {
        this.$store.commit('contact/setCurrentPage', page);
        this.getPage();
    }

    pageSizeChange(pageSize: number) {
        this.$store.commit('contact/setPageSize', pageSize);
        this.getPage();
    }

    async getPage() {

        this.pageRequest.maxResultCount = this.pageSize;
        this.pageRequest.skipCount = (this.currentPage - 1) * this.pageSize;

        await this.$store.dispatch({
            type: 'contact/getAll',
            data: this.pageRequest
        })
    }

    get pageSize() {
        return this.$store.state.contact.pageSize;
    }

    get totalCount() {
        return this.$store.state.contact.totalCount;
    }

    get currentPage() {
        return this.$store.state.contact.currentPage;
    }

    columns = [{
        title: this.L('FirstName'),
        key: 'firstName',
        sortable: 'custom'
    }, {
        title: this.L('LastName'),
        key: 'lastName',
        sortable: 'custom'
    }, {
        title: this.L('EmailAddress'),
        key: 'emailAddress',
        sortable: 'custom'
    }, {
        title: this.L('HasAccount'),
        key: 'hasAccount',
        width: 150,
    }, {
        title: this.L('Actions'),
        key: 'Actions',
        width: 270,
        render: (h: any, params: any) => {
            return h('div', [
                h('Button', {
                    props: {
                        type: 'primary',
                        size: 'small'
                    },
                    style: {
                        marginRight: '5px',
                        visibility: this.checkClonePermissions(params.row.hasAccount) ? 'visible' : 'hidden'
                    },
                    on: {
                        click: async () => {
                            await this.$store.dispatch({
                                type: 'contact/registerUser',
                                data: new RegisterUserForContact(params.row.id)
                            });
                            await this.getPage();
                        }
                    }
                }, this.L('Create account')),
                h('Button', {
                    props: {
                        type: 'primary',
                        size: 'small'
                    },
                    style: {
                        marginRight: '5px'
                    },
                    on: {
                        click: async () => {
                            await this.edit(params.row.id);
                        }
                    }
                }, this.L('Edit')),
                h('Button', {
                    props: {
                        type: 'error',
                        size: 'small'
                    },
                    on: {
                        click: async () => {
                            this.$Modal.confirm({
                                title: this.L('Tips'),
                                content: this.L('Delete Contact Confirm'),
                                okText: this.L('Yes'),
                                cancelText: this.L('No'),
                                onOk: async () => {
                                    await this.$store.dispatch({
                                        type: 'contact/delete',
                                        data: params.row
                                    })
                                    await this.getPage();
                                }
                            })
                        }
                    }
                }, this.L('Delete'))
            ])
        }
    }]

    checkClonePermissions(hasAccount) {
        return window.abp.auth.hasPermission('Pages.RegisterUser') && hasAccount !== 'Yes';
    }

    async created() {
        await this.getPage();
    }
}
