














































import {Component, Vue, Inject, Prop, Watch, Emit} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import Contact from "@/store/entities/companycontext/contact";

@Component
export default class ContactForm extends AbpBase {
  @Prop({type: Boolean, default: false}) value: boolean;
  @Prop({type: Boolean, default: false}) toEdit: boolean;
  contact: Contact = new Contact();
  private imagePresents: boolean = false;

  mounted() {
    this.contact = Util.extend(true, {}, this.$store.state.contact.editContact);
    if (this.contact.signature) {
      this.imagePresents = true;
    }
  }

  getContact() {
    let formData = new FormData();
    if (this.contact.landline) {
      formData.append('landline', this.contact.landline);
    }
    if (this.contact.id) {
      formData.append('id', this.contact.id.toString());
    }
    if (this.contact.mobilePhone) {
      formData.append('mobilePhone', this.contact.mobilePhone);
    }
    if (this.contact.emailAddress) {
      formData.append('emailAddress', this.contact.emailAddress);
    }
    if (this.contact.lastName) {
      formData.append('lastName', this.contact.lastName);
    }
    if (this.contact.firstName) {
      formData.append('firstName', this.contact.firstName);
    }
    if (this.contact.note) {
      formData.append('note', this.contact.note);
    }
    if (this.contact.uploadedSignature) {
      formData.append('signature', this.contact.uploadedSignature);
    } else {
      if (this.contact.signature) {
        formData.append('signature', this.b64toBlob(this.contact.signature));
      }
    }
    return formData;
  }

  b64toBlob(dataURI) {
    let byteString = atob(dataURI.split(',')[1]);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type: dataURI.match(/[^:]\w+\/[\w-+\d.]+(?=[;,])/)[0]});
  }

  updateContact() {
    this.contact = Util.extend(true, {}, this.$store.state.contact.editContact);
  }

  handleUpload(file) {
    this.contact.uploadedSignature = file;
    this.contact.signature = URL.createObjectURL(file);
    this.imagePresents = true;
  }
}

